import React, { FC } from 'react';
import YouTube, { Options } from 'react-youtube';
import { InstructionVideoProps } from './models';

import './InstructionVideo.scss';

const InstructionVideo: FC<InstructionVideoProps> = ({ header, videoID }) => {
  const videoOpts: Options = {
    playerVars: {
      controls: 1,
      autoplay: 0,
      loop: 1,
      showinfo: 0,
      modestbranding: 1,
    },
  };

  return (
    <div>
      <h2 className="mt-5 mb-4" id="instructions">
        {header}
      </h2>
      <div className="video mx-0 mx-md-5">
        <div className="video-wrapper">
          <YouTube videoId={videoID} opts={videoOpts} />
        </div>
      </div>
    </div>
  );
};

export default InstructionVideo;
