import React, { FC } from 'react';
import Instruction from 'components/Instruction';
import Product from 'components/Product';
import { Carousel } from 'react-responsive-carousel';
import Image from 'common/Image';
import Modal from 'react-bootstrap/Modal';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getUrl, getTarget } from '../../utils/browser';
import { HomeBottomProps } from './models';

import './HomeBottom.scss';

const HomeBottom: FC<HomeBottomProps> = ({
  instructions,
  notesHeader,
  notes,
  secondaryBannersHeader: [secondaryBannersHeader],
  articles,
  productsHeader,
  products,
  faqsHeader,
  faqs,
}) => {
  const [show, setShow] = React.useState(false);
  const hideNotes = () => setShow(false);
  const showNotes = () => setShow(true);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <Instruction items={instructions} />
          <div className="text-center">
            <button className="btn btn-secondary mt-4 px-4" type="button" onClick={showNotes}>
              {notesHeader}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2 className="inverted line-secondary mt-5 mb-4">
            <a
              className="text-decoration-none"
              href={getUrl(secondaryBannersHeader)}
              target={getTarget(secondaryBannersHeader)}
            >
              <span>{secondaryBannersHeader.name}</span>
            </a>
          </h2>
          <div className="px-3">
            <div className="nutrition mx-auto mb-2">
              <Carousel showThumbs={false} showIndicators showArrows showStatus={false}>
                {articles.map((item, index) => (
                  <div key={index} data-testid="home-bottom-article-item">
                    <div className="article-listing__item">
                      <div>
                        <Image imageData={item.image} alt={item.image.altText} />
                      </div>
                      <div className="px-3 text-center pb-3">
                        <h3 className="line-secondary pt-2">
                          <a href={item.link} className="text-white" title={item.title}>
                            {item.title}
                          </a>
                        </h3>
                        <p className="mt-4 mb-2 px-2">
                          <a href={item.link} className="text-white" title={item.title}>
                            {item.resume}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Product header={productsHeader} items={products} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2 className="mt-5 mb-4 line-primary">{faqsHeader}</h2>
          <div className="faqs">
            <Accordion>
              {faqs.map(({ title, body }, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="text-right text-md-left">{title}</div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <DangerouslySetInnerHtml
                      html={body}
                      element="div"
                      className="mb-0 text-right text-md-left"
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={hideNotes}>
        <Modal.Dialog className="my-0">
          <Modal.Header closeButton />
          <Modal.Body className="p-0 border-0">
            <div className="p-3">
              <DangerouslySetInnerHtml
                html={notes.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2')}
                element="small"
              />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </div>
  );
};

export default HomeBottom;
