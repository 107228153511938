import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ProductItem from 'components/ProductItem';
import { ProductProps } from './models';
import './Product.scss';

const Product: FC<ProductProps> = ({ header, items }) => (
  <div className="products px-3">
    <h2 className="mt-5 mb-4 line-secondary">{header}</h2>
    <Carousel showThumbs={false} showIndicators showArrows showStatus={false}>
      {items.map((item, index) => (
        <ProductItem key={index} data={item} />
      ))}
    </Carousel>
  </div>
);

export default Product;
