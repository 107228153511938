import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import ImageGallery from 'react-image-gallery';
import PageSchema from 'common/PageSchema';
import HomeMiddle from 'components/HomeMiddle';
import HomeBottom from 'components/HomeBottom';
import { HomepageProps } from './models';
import 'styles/main.scss';
import './HomePage.scss';

const HomePage: FC<{ data: HomepageProps }> = ({
  data: {
    homepageSettings: { seoMetaTitle, seoMetaDescription, seoMetaKeywords },
    allArticle: { nodes },
    homepage: {
      banners,
      bannersMobile,
      warning,
      video,
      aboutHeader,
      about,
      formulas,
      nutritionalButton,
      nutritional,
      instructionsHeader,
      instructions,
      notesHeader,
      notes,
      secondaryBannersHeader,
      productsHeader,
      products,
      faqsHeader,
      faqs,
      preparation,
    },
  },
}) => {
  const bannerImages = banners.map(({ image: { url, altText }, url: link }) => ({
    original: url,
    originalAlt: altText,
    link: link ? (link.length > 0 ? link[0].url : null) : null,
  }));

  const bannerMobileImages = bannersMobile.map(({ image: { url, altText }, url: link }) => ({
    original: url,
    originalAlt: altText,
    link: link ? (link.length > 0 ? link[0].url : null) : null,
  }));

  const formulaImages = formulas.map(({ image: { url, altText } }) => ({
    original: url,
    originalAlt: altText,
  }));

  const youtube = (item) =>
    item
      .map(({ url }) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[7].length === 11 ? match[7] : false;
      })
      .shift();

  const videoID = youtube(video);

  const preparationID = youtube(preparation);

  let imageGallery;
  let imageGalleryMobile;

  const clickImage = () => {
    const { link } = bannerImages[imageGallery.getCurrentIndex()];
    if (link) {
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  const clickImageMobile = () => {
    const { link } = bannerMobileImages[imageGalleryMobile.getCurrentIndex()];
    if (link) {
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <Layout headerTransparent>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div id="home">
        <section id="home__banner" className="pb-0">
          <div className="d-none d-md-block">
            <ImageGallery
              ref={(i) => {
                imageGallery = i;
              }}
              items={bannerImages}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets
              onClick={clickImage}
            />
          </div>
          <div className="d-block d-md-none">
            <ImageGallery
              ref={(i) => {
                imageGalleryMobile = i;
              }}
              items={bannerMobileImages}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets
              onClick={clickImageMobile}
            />
          </div>
        </section>
        <section id="home__middle">
          <HomeMiddle
            warning={warning}
            videoID={videoID}
            preparationID={preparationID}
            about={about}
            aboutHeader={aboutHeader}
            formulas={formulas}
            formulaImages={formulaImages}
            nutritional={nutritional}
            nutritionalButton={nutritionalButton}
            instructionsHeader={instructionsHeader}
          />
        </section>
        <section id="home__bottom">
          <HomeBottom
            instructions={instructions}
            notesHeader={notesHeader}
            notes={notes}
            secondaryBannersHeader={secondaryBannersHeader}
            articles={nodes}
            productsHeader={productsHeader}
            products={products}
            faqsHeader={faqsHeader}
            faqs={faqs}
          />
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    homepageSettings {
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
    }
    allArticle {
      nodes {
        id
        title
        link
        body
        resume
        image {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
        }
      }
    }
    homepage {
      title
      banners {
        image {
          url
          altText
        }
        url {
          url
        }
      }
      bannersMobile {
        image {
          url
          altText
        }
        url {
          url
        }
      }
      warning
      video {
        url
      }
      preparation {
        url
      }
      aboutHeader
      about
      formulas {
        title
        image {
          id
          url
          altText
        }
      }
      nutritionalButton
      nutritional {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
      }
      instructionsHeader
      instructions {
        image {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1024)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1024)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1024)
            }
          }
        }
        label
      }
      notesHeader
      notes
      secondaryBannersHeader {
        name
        url
        queryString
        target
      }
      productsHeader
      products {
        image {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
        }
        imageLateral {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 560)
            }
          }
        }
        imageBack {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 360)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 360)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 360)
            }
          }
        }
        title
        type
        description
        link {
          name
          url
          queryString
          target
        }
        external {
          name
          url
          queryString
          target
        }
      }
      faqsHeader
      faqs {
        title
        body
      }
    }
  }
`;

export default HomePage;
