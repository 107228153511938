import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Image from 'common/Image';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { InstructionProps } from './models';

import './Instruction.scss';

const Instruction: FC<InstructionProps> = ({ items }) => (
  <div>
    <ul className="instructions list-unstyled p-0 mb-0 d-none d-md-block">
      {items.map(({ image, label }, index) => (
        <li className="d-flex align-items-center" key={index} data-testid="instruction-item">
          <div className="instructions-image">
            <Image imageData={image} alt={image.altText} />
          </div>
          <div className="instructions-content d-flex align-items-center">
            <label className="mx-3 mb-0" htmlFor={`instruction-${index}`}>
              {index + 1}
            </label>
            <div id={`instruction-${index}`}>
              <DangerouslySetInnerHtml html={label} element="p" className="mb-0 px-2" />
            </div>
          </div>
        </li>
      ))}
    </ul>
    <Carousel
      showThumbs={false}
      showIndicators
      showArrows
      showStatus={false}
      className="d-block d-md-none instructions-mobile mx-4"
    >
      {items.map(({ image, label }, index) => (
        <div key={index}>
          <div className="instructions-mobile__image">
            <label className="mx-1 mb-0" htmlFor={`instruction-mobile-${index}`}>
              {index + 1}
            </label>
            <Image imageData={image} alt={image.altText} />
          </div>
          <p className="mb-4 px-2" id={`instruction-mobile-${index}`}>
            <DangerouslySetInnerHtml html={label} element="span" />
          </p>
        </div>
      ))}
    </Carousel>
  </div>
);

export default Instruction;
