import React, { FC } from 'react';
import Image from 'common/Image';
import Modal from 'react-bootstrap/Modal';
import { ProductItemProps } from './models';
import './ProductItem.scss';

import { getUrl, getTarget } from '../../utils/browser';

const ProductItem: FC<ProductItemProps> = ({
  data: {
    image,
    imageLateral,
    imageBack,
    link: [link],
    external: [external],
    ...item
  },
}) => {
  const [selected, setState] = React.useState(0);
  const select = (index) => {
    setState(index);
  };

  const images = [image, imageLateral, imageBack];
  const [show, setShow] = React.useState(false);
  const hideProduct = () => setShow(false);
  const showProduct = () => setShow(true);

  return (
    <div data-testid="product-item">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-block d-md-none">
            <h5>
              <strong>{item.title}</strong>
            </h5>
            <h6>{item.type}</h6>
          </div>
          <div className="col-12 col-md-5">
            <div className="products__item-selected d-flex align-items-center justify-content-center pt-2">
              <button
                onClick={() => showProduct()}
                type="button"
                className="bg-transparent border-0 p-0"
              >
                {selected === 0 && <Image imageData={images[0]} alt={images[0].altText} />}
                {selected === 1 && <Image imageData={images[1]} alt={images[1].altText} />}
                {selected === 2 && <Image imageData={images[2]} alt={images[2].altText} />}
              </button>
            </div>
            <ul className="list-unstyled d-flex justify-content-center mx-5 mt-2">
              {images.map((img, index) => (
                <li className="products__item mx-2" key={index}>
                  <button
                    className="p-1 flex-grow-1 bg-white"
                    type="button"
                    onClick={() => select(index)}
                  >
                    <Image imageData={img} alt={img.altText} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-md-7 text-center text-md-left pb-2">
            <div className="d-none d-md-block">
              <h5>
                <strong>{item.title}</strong>
              </h5>
              <h6>{item.type}</h6>
            </div>
            <a
              className="btn btn-secondary my-2 text-white"
              href={getUrl(link)}
              target={getTarget(link)}
            >
              {link.name}
            </a>
            <p>{item.description}</p>
            <a
              className="btn btn-secondary my-2 text-white"
              href={getUrl(external)}
              target={getTarget(external)}
            >
              {external.name}
            </a>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={hideProduct}>
        <Modal.Dialog className="my-0">
          <Modal.Header closeButton />
          <Modal.Body className="p-0 border-0">
            <div className="p-3">
              {selected === 0 && <Image imageData={images[0]} alt={images[0].altText} />}
              {selected === 1 && <Image imageData={images[1]} alt={images[1].altText} />}
              {selected === 2 && <Image imageData={images[2]} alt={images[2].altText} />}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </div>
  );
};

export default ProductItem;
