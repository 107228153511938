import React, { FC } from 'react';
import ImageGallery from 'react-image-gallery';
import InstructionVideo from 'components/InstructionVideo';
import YouTube, { Options } from 'react-youtube';
import Modal from 'react-bootstrap/Modal';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';
import { HomeMiddleProps } from './models';

import './HomeMiddle.scss';

const HomeMiddle: FC<HomeMiddleProps> = ({
  warning,
  aboutHeader,
  about,
  formulas,
  formulaImages,
  nutritional,
  nutritionalButton,
  instructionsHeader,
  videoID,
  preparationID,
}) => {
  const videoOpts: Options = {
    playerVars: {
      controls: 1,
      autoplay: 0,
      loop: 1,
      showinfo: 0,
      modestbranding: 1,
    },
  };

  const [show, setShow] = React.useState(false);
  const hideNutritional = () => setShow(false);
  const showNutritional = () => setShow(true);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col pt-4">
            <div className="mx-md-5 px-2">
              <h4 className="text-uppercase text-center py-2 px-2 px-md-5 mx-md-5 mb-4">
                {warning}
              </h4>
            </div>
            <div className="video mx-0 mx-md-5">
              <div className="video-wrapper">
                <YouTube videoId={videoID} opts={videoOpts} />
              </div>
            </div>
          </div>
        </div>
        <div className="row formulas">
          <div className="col text-center">
            <DangerouslySetInnerHtml html={aboutHeader} element="h2" className="mt-4" />
            <p className="text-center text-white mt-3 pre-wrap">
              <strong>{about}</strong>
            </p>
            <ul className="unstyled-list p-0 d-none d-md-flex">
              {formulas.map(({ image: { url, altText } }, index) => (
                <li className="d-inline-block mx-5" key={index}>
                  <img src={url} className="img-fluid" alt={altText} />
                </li>
              ))}
            </ul>
            <div className="d-block d-md-none">
              <ImageGallery
                originalClass="image__gallery"
                items={formulaImages}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
                showBullets={false}
              />
            </div>
            <button className="btn btn-secondary mt-4 px-4" type="button" onClick={showNutritional}>
              {nutritionalButton}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InstructionVideo header={instructionsHeader} videoID={preparationID} />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={hideNutritional} size="lg">
        <Modal.Dialog className="my-0">
          <Modal.Header closeButton />
          <Modal.Body className="p-0 border-0">
            <Image imageData={nutritional} alt={nutritional.altText} />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </div>
  );
};

export default HomeMiddle;
